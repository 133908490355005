.roadmap-heading{
    text-transform: uppercase;
	background: linear-gradient(to right, #3FFF47 0%, #3FFFD1
    100%);
	background-clip: text;
	-webkit-text-fill-color: transparent;
    margin-top: 10%;
    font-weight: 700;
}
.rectangular-div{
    width: 100%;
    height: 300px;
    border: 3px solid;
   text-align: left;
    border-image: linear-gradient(to right, #3FFF47
 , #3EF7D2
 ) 1;

}.main-div{
    width: 25%;
    vertical-align: top;
    display: inline-block;
    margin: 2.5%;
    align-content: center;
}.roadmap-text{
    color: white;
    padding: 5px;
    margin-left: 5%;
}
.circular-div{
    border: 3px solid;
   width: 50px;
   height: 50px;
   border-image: linear-gradient(to right, #3FFF47
 , #3EF7D2
 ) 1; 
 border-bottom: 0px;
 border-radius: 50px;
}
@media screen and (max-width: 840px){
.main-div{
  width: 90%;
  margin-top: 10%;      
}
.rectangular-div{
  height: auto;
    }
}
