.team-span{
    display: inline-block;
    width: 25%;
    margin: 2.5%;
    text-align: center;
}.team{
    margin-top: 5%;
}.team-heading{
    text-transform: uppercase;
    margin-bottom: 4%;
	background: linear-gradient(to right, #3FFF47 0%, #3FFFD1
    100%);
    font-weight: 600;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}.team-image{
    width: 45%;
    display: inline-block;
    border: 3px solid ;
 border-image: linear-gradient(to right, #3FFF47
 , #3EF7D2
 ) 1;
}
.team-text{
    font-size: 1vw;
    margin-left: -15%;
    font-weight: 300;
}.team-name{
    float: right;
    text-align: left;
    font-family: gos;

}.linkedin-logo{
    cursor: pointer;
    margin-left: -10%;

}@media screen and (max-width: 840px){
    .team-text{
        font-size: 3vw;
    margin-left: 0;    }
        .team-image{
            width: 100%;
        }.linkedin-logo{
            width: 10%;
            margin-left: 0;
        }.team-name{
            float: none;
        }
}
