.community-div1{
    width: 40%;
    display:inline-block;
    margin: 5%;
    margin-top: 10%;
}
.community-img1{
    width: 100%;
}
.community-text{
    font-size: 6vw;
}
.community-button{
    background: linear-gradient(89.94deg, #3FFF47 0.04%, #3FFFD1 175.49%);
    border-radius: 5px;
    padding: 1%;
    border: 0;
    color: black;
}
.community-heading{
	text-transform: uppercase;
	background: linear-gradient(to right, #3FFF47 0%, #3FFFD1
	100%);
	background-clip: text;
	-webkit-text-fill-color: transparent;
	margin-top: 10%;
	margin-bottom: 5%;
	font-weight: 700;
}
@media screen and (max-width: 840px){
    .community-div1{
        width: 90%;
    }
}
