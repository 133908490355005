.part1{
    width: 40%; 
    display: inline-block;
    vertical-align: top;
}
.about-heading{
    margin-top: 10%;
    text-transform: uppercase;
	background: linear-gradient(to right, #3FFF47 0%, #3FFFD1 100%);
	background-clip: text;
	-webkit-text-fill-color: transparent;
    font-weight: 700;
}
.img1{
    width:40%;
    display: inline-block;
    vertical-align: top;
}
.img2{
   width: 40%;
   display: inline-block;
   margin-left: 10%;
}

.part2{
   width: 40%;
   margin-right:10%;
   margin-left: 0;
   display: inline-block;
   text-align: left;
}
.about-text{
    color: white;    
}
.allimg1{
    margin: 13%;
    margin-top: 0;
    width: 100%;
}
.allimg{
    margin: 13%;
    margin-top: 0;
    width: 100%;
    margin-left: 0;
}
@media screen and (max-width: 786px){
    .part1{
        width: 90%;
    }
    .part2{
        width: 90%;
        text-align: left;
        margin: 5%;
    }
    .about-heading{
        margin-top: 10%;
    }
}
